<!-- TODO: Split some component -->

<script lang="ts" setup>
import { computed, inject, nextTick, onMounted, provide, ref, set, watch } from 'vue'
import { onLongPress, useEventBus, useInterval, useLocalStorage, useScroll, useStorage, useTitle, watchOnce, watchThrottled } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router/composables'
import { isPast } from 'date-fns'
import { $formatErrorMessage, $getToken, $gotoPayProduct, $loadTextFromCDN } from '@/helpers'
import { clamp, diffSeconds, fen2yuan, isDefined, remove, sample, sleep } from '@/utils'
import { api } from '@/api'
import { PAY_LOCATION_PAGE, STORAGE_CUSTOM_THEME, STORAGE_RECHARGE_INTERCEPTION, STORAGE_SIGN_PRODUCT_ID, TRACK_CHANNEL_NAME_VALUE } from '@/constants'
import { useConfigStore, useHistoryStore, useLoadingStore, useQueryStore, useReadStore, useToastStore, useTrackValueStore, useUserStore } from '@/stores'
import { track, trackRealtime } from '@/track'
import { useGotoBack } from '@/composables'

const props = defineProps<{
  contentId: string
  chapterId: string
}>()

const route = useRoute()
const router = useRouter()
const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()
const gotoBack = useGotoBack()
const readStore = useReadStore()
const configStore = useConfigStore()
const userStore = useUserStore()
const queryStore = useQueryStore()
const trackValueStore = useTrackValueStore()

const rechargeInterception = useStorage<Record<string, any>>(STORAGE_RECHARGE_INTERCEPTION, { _exist: false })
const eventBusRechargeInterception = useEventBus<string>('modal:rechargeInterception')

const bizModalRechargeInterception = inject<any>('bizModalRechargeInterception')

const skipRechargeInterception = ref(false)
const cancel = ref(false)
function checkRechargeInterceptionModal() {
  if (route.query.skipRechargeInterception)
    skipRechargeInterception.value = true

  // Note: 如果是支付取消返回的页面
  if (route.query.cancel) {
    cancel.value = true

    if (!skipRechargeInterception.value && cancel.value)
      eventBusRechargeInterception.emit('open')

    skipRechargeInterception.value = true

    router.replace({
      query: {
        ...route.query,
        cancel: undefined,
        skipRechargeInterception: '1',
      },
    })
  }
}

const finishRecommend = ref(false)
onMounted(() => {
  // pay redirect back
  const trackValueStore = useTrackValueStore()
  trackValueStore.setFromLocal(props.contentId)
  // Note: remove when expired
  if (isPast(rechargeInterception.value?.expiredAt))
    rechargeInterception.value = { _exist: false }
})

const containerRef = ref<HTMLElement>()
provide('containerRef', containerRef)

const skeleton = ref(true)
const title = useTitle('加载中...')
// Fetch chapter
const book = ref<GetContentGetcontentdetailResponse['data']>()
const chapter = ref<PostReadPlayResponse['data'] | null>()
const content = ref('')
const paragraphs = computed(() => {
  if (!content.value)
    return []
  return content.value.split(/[\n<br>]/).filter(Boolean)
})
const { counter: readTime, reset: resetReadTime } = useInterval(1000, { controls: true })
function trackReadTime() {
  // Note: 跳过 如果章节加锁
  if (chapter.value?.subscribeState === 4)
    return

  trackValueStore.refresh()
  window.collectEvent('contentEnd', {
    content_id: props.contentId,
    content_name: book.value?.contentName,
    chapter_index: chapter.value?.sequenceNo,
    chapter_max: book.value?.chapterNum,
    play_time: readTime.value,
    first_origin: trackValueStore.$state.firstOrigin,
    origin_name: trackValueStore.$state.originName,
    chl_name: trackValueStore.$state.chlName,
  })
}

async function fetchBook() {
  const { data } = await api.get<any, GetContentGetcontentdetailResponse>('/content/getContentDetail', { params: { contentId: props.contentId } })
  book.value = data
}
async function fetchLatestPosition() {
  const { data } = await api.post<any, PostReadPosResponse>('/read/pos', { contentId: book.value!.id })
  router.replace({
    name: 'chapter',
    params: { contentId: book.value!.id, chapterId: data.contentChapterId },
    query: { scrollY: String(data.sectionMediaPlayedSeconds) },
  })
  return props.chapterId !== data.contentChapterId
}
async function fetchChapter() {
  // Note: 每次获取章节的是, 判断是否设置 finishRecommend
  if (route.query.finishRecommend)
    finishRecommend.value = true

  try {
    const { data } = await api.post<any, PostReadPlayResponse>('/read/play', {
      contentId: props.contentId,
      contentChapterId: props.chapterId,
      sequenceNo: 1,
    })
    checkRechargeInterceptionModal()
    getQwFansInfo(data)
    chapter.value = data
    content.value = await $loadTextFromCDN(data.bookChapterFileUrl)
    const scrollY = Number(route.query.scrollY ?? 0)
    title.value = route.query.finishRecommend ? '' : chapter.value?.contentChapterName
    const { y } = useScroll(containerRef)
    nextTick(() => {
      y.value = scrollY
    })
    // tracks
    trackValueStore.refresh()
    window.collectEvent('contentStart', {
      content_id: props.contentId,
      content_name: book.value!.contentName,
      chapter_index: chapter.value!.sequenceNo,
      chapter_max: book.value!.chapterNum,
      first_origin: trackValueStore.$state.firstOrigin,
      origin_name: trackValueStore.$state.originName,
      chl_name: trackValueStore.$state.chlName,
      is_chapterend_recommend: finishRecommend.value ? '是' : '否',
      // 1:章节免费、2:已购买、3:VIP、4:余额不足，
      pay_status: ['', '章节免费', '已购买', 'VIP', '余额不足'][chapter.value!.subscribeState ?? 0],
    })
    track('contentPlay', {
      contentId: props.contentId,
      chapterId: props.chapterId,
      contentName: book.value!.contentName,
      itemType: 'novel',
      is_chapterend_recommend: finishRecommend.value ? '是' : '否',
      pay_status: ['其他', '章节免费', '已购买', 'VIP', '余额不足'][chapter.value!.subscribeState ?? 0],
    })
    nextTick(() => {
      reportScrollLocation(0)
    })
    destroyPopupRecharge()
    // Note: for back last read chapter
    readStore.update({
      contentId: props.contentId,
      chapterId: chapter.value?.contentChapterId as string,
    })
  }
  catch (error: any) {
    const { data, code } = error
    // Note: 特殊业务处理, 403 为未购买章节
    if (code === 403) {
      chapter.value = data
      chapter.value!._pay = true
      content.value = ''
      // content.value = await $loadTextFromCDN(data.bookChapterFileUrl)

      // Note: 设置企微导粉相关信息
      if (data.wpImportPopWeight === 1) // 1: 优先企微弹窗, 2: 优先支付弹窗
        getQwFansInfo(data)
      // Note: 是否开启 VIP 购买活动
      else if (configStore.$state.renewIceBreakingState)
        openPopupVip()
      else
        openPopupRecharge()

      trackValueStore.refresh()
      window.collectEvent('contentStart', {
        content_id: props.contentId,
        content_name: book.value!.contentName,
        chapter_index: chapter.value!.sequenceNo,
        chapter_max: book.value!.chapterNum,
        first_origin: trackValueStore.$state.firstOrigin,
        origin_name: trackValueStore.$state.originName,
        chl_name: trackValueStore.$state.chlName,
        is_chapterend_recommend: book.value!.finishStatus ? '是' : '否',
        pay_status: '余额不足',
      })
      track('contentPlay', {
        contentId: props.contentId,
        chapterId: props.chapterId,
        contentName: book.value!.contentName,
        itemType: 'novel',
        is_chapterend_recommend: finishRecommend.value ? '是' : '否',
        pay_status: '付费章节',
      })
      window.collectEvent('elementShow', {
        type: '充值遮罩页',
        page: '阅读页',
        content_id: props.contentId,
        content_name: book.value!.contentName,
        chapter_index: chapter.value!.sequenceNo,
        is_chapterend_recommend: finishRecommend.value ? '是' : '否',
        pay_status: '余额不足',
        name: '阅读页充值档位弹窗',
      })
      return
    }
    console.error(error)
  }
  finally {
    resetReadTime()
    skeleton.value = false
  }
}
onMounted(async () => {
  skeleton.value = true
  try {
    await fetchBook()
    if (!route.query.skipPosition) {
      // Note: existed different chapterId, void duplicate request chapter
      const existed = await fetchLatestPosition()
      if (existed)
        return
    }
    await fetchChapter()
  }
  catch (error) {
    $toast($formatErrorMessage(error))
  }
  finally {
    skeleton.value = false
  }
})
watch(() => props.contentId, () => {
  fetchBook()
})
watch(() => props.chapterId, () => {
  skeleton.value = true
  chapter.value = null
  content.value = ''
  trackReadTime()
  fetchChapter()
})

// Prev & Next
function onPrevChapter() {
  if (!chapter.value!.prev)
    return

  router.replace({ name: 'chapter', params: { contentId: props.contentId, chapterId: chapter.value!.prev.contentChapterId } })
}
function onNextChapter() {
  if (checkForceQWDialog())
    return

  if (checkForceModalQrcode())
    return

  if (!chapter.value!.next) {
    onPagePulled()
    return
  }

  // Note: 如果是从章节完结推荐来的时候, 返回上一级需要返回首页
  if (route.query.finishRecommend) {
    const historyStore = useHistoryStore()
    historyStore.$clear()
  }
  router.replace({ name: 'chapter', params: { contentId: props.contentId, chapterId: chapter.value!.next.contentChapterId } })
}

// Popup: TOC(Table of Contents)
const popupTOC = ref(false)
watch(() => route.fullPath, () => {
  popupTOC.value = false
})

// Popup: Theme
// TODO: Move to constants/config?
const THEME_FONT_MAP = ['24', '26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '46', '48', '50']
const THEME_BACKGROUNDS = ['#FFF', '#F5E9CF', '#D2ECD3', '#D0E6FE', '#F6E6E1']
const theme = useLocalStorage(STORAGE_CUSTOM_THEME, {
  background: '#F5E9CF',
  size: '42',
})
const THEME_FONT = computed(() => {
  if (!THEME_FONT_MAP.includes(theme.value.size))
    set(theme.value, 'size', '42')

  return {
    chapterTitle: `font-size: ${Number.parseInt(theme.value.size) / 2 + 2}px;font-weight: 500;`,
    chapterContent: `margin-top: 1em;font-size: ${Number.parseInt(theme.value.size) / 2}px;line-height: 1.7em;`,
    paragraphSpacing: 'margin-top: 1em;',
  }
})
provide('theme', theme)

const popupTheme = ref(false)
watch(() => route.fullPath, () => {
  popupTheme.value = false
})
function onToggleTheme() {
  popupTOC.value = false
  popupTheme.value = !popupTheme.value
}
function onChangeThemeSize(zoom: number) {
  const index = clamp(THEME_FONT_MAP.indexOf(theme.value.size) + zoom, 0, THEME_FONT_MAP.length - 1)
  set(theme.value, 'size', THEME_FONT_MAP[index])
}

// 充值加购
const rechargeAddState = ref(false)
const rechargeAdd = ref<any>()
async function getRechargeAdd() {
  try {
    const { data } = await api.get('/recharge/strategy/getStrategy')
    if (data?.id) {
      data.productMap = data.productMapList ?? {}
      if (Object.keys(data.productMap).length)
        rechargeAdd.value = data
      if (data.rechargeStrategyFlag === 1)
        rechargeAddState.value = true

      // 15: 充值加购
      window.collectEvent('elementShow', {
        item_type: 15,
        item_id: data.id,
        is_chapterend_recommend: finishRecommend.value ? '是' : '否',
      })
      trackRealtime('pageView',
        { activityId: data.id, userToken: $getToken() },
        { sourceId: data.id, sourceType: 15 },
      )
    }
  }
  catch {
    console.error('获取充值加购失败')
    rechargeAdd.value = null
  }
}
function onChangeShowAdd() {
  rechargeAddState.value = !rechargeAddState.value

  // 15: 充值加购
  window.collectEvent('buttonClick', {
    page: '阅读页',
    button_name: rechargeAddState.value ? '打开加购' : '关闭加购',
    state: rechargeAddState.value,
  })
  window.collectEvent('elementClick',
    { item_type: 15, item_id: rechargeAdd.value.id, state: rechargeAddState.value },
  )
  trackRealtime('activityReceived',
    { activityId: rechargeAdd.value?.id ?? '', userToken: $getToken(), state: rechargeAddState.value },
    { sourceId: rechargeAdd.value?.id, sourceType: 15 },
  )
}

// Popup: recharge
const popupRecharge = ref(false)
const popupVip = ref(false)

const products = ref<any[]>([])
async function openPopupRecharge() {
  popupRecharge.value = true
  await getRechargeAdd()
  const { data } = await api.get<any, GetRechargetemplateGetproductlistResponse>('/rechargeTemplate/getProductList')
  products.value = data.sort((a, b) => a.orderNumber - b.orderNumber)
}
function closePopupRecharge() {
  gotoLastChapter()
  if (!skipRechargeInterception.value)
    eventBusRechargeInterception.emit('open')
  skipRechargeInterception.value = true
}
function destroyPopupRecharge() {
  popupRecharge.value = false
  products.value = []
}

const iceBreakProduct = ref<any>()
async function openPopupVip() {
  popupVip.value = true
  // 开启破冰
  if (configStore.$state.renewIceBreakingState) {
    try {
      const { data: product } = await api.get('/rechargeTemplate/getIceBreakingProduct')
      iceBreakProduct.value = product
    }
    catch {}
  }
  const { data } = await api.get<any, GetRechargetemplateGetproductlistResponse>('/rechargeTemplate/getProductList')
  products.value = data.sort((a, b) => a.orderNumber - b.orderNumber)
}
function closePopupVip() {
  popupVip.value = false
  gotoLastChapter()
}

// Note: 如果存在破冰 & 样式 2, products 最多取 5 个
const balanceProducts = computed(() => {
  if (iceBreakProduct.value && products.value.length) {
    // 取双数
    if (configStore.$state.renewIceBreakingStyleConfig === '2')
      return products.value.slice(0, 5)
  }
  return products.value
})

function gotoLastChapter() {
  if (readStore.$state.contentId && readStore.$state.contentId === props.contentId && readStore.$state.chapterId)
    router.replace({ name: 'chapter', params: { contentId: readStore.$state.contentId, chapterId: readStore.$state.chapterId } })
  else
    gotoBack()
}

// Report scroll `y` position
async function reportScrollLocation(y: number = 0) {
  try {
    await api.post<any, PostReadReportResponse>('/read/report', {
      contentId: props.contentId,
      contentChapterId: props.chapterId,
      sectionMediaPlayedSeconds: Math.floor(y),
      videoDuration: containerRef.value ? (containerRef.value.scrollHeight - containerRef.value.clientHeight) : 0,
    })
  }
  catch { } // TODO: Report Error
}
const { y, arrivedState } = useScroll(containerRef)
watchThrottled(
  y,
  async () => {
    !popupRecharge.value && reportScrollLocation(y.value)
  },
  { throttle: 2000, leading: true },
)

// Modal: qrcode
const modalQrcode = ref(false)

function checkForceModalQrcode() {
  if (!chapter.value)
    return false
  if (chapter.value.subscribedMp)
    return false
  if (configStore.$state.noPopup)
    return false
  if (configStore.$state.forceAttentionMp && chapter.value.sequenceNo >= chapter.value.contentStartSubscribeChapter) {
    modalQrcode.value = true
    return true
  }
  return false
}
function onTOCCheckPass(index: number) {
  if (!chapter.value)
    return true
  if (index >= chapter.value.sequenceNo) {
    popupTOC.value = false
    return !checkForceQWDialog()
  }
  if (chapter.value.subscribedMp)
    return true
  if (configStore.$state.noPopup)
    return true
  if (configStore.$state.forceAttentionMp && index >= chapter.value.contentStartSubscribeChapter) {
    modalQrcode.value = true
    popupTOC.value = false
    return false
  }
  return true
}

const pageSwiperRef = ref()
const bookFinished = computed(() => {
  return book.value?.lastChapter?.id === props.chapterId || !chapter.value?.next
})
async function onPagePulled() {
  try {
    $showLoading()
    const books = await fetchRecommends()
    if (!books)
      return
    const nextBook = books[0]
    const historyStore = useHistoryStore()
    historyStore.$push(route.fullPath)
    trackValueStore.set({ bookId: nextBook.contentId, firstOrigin: 5, originName: 5, chlName: TRACK_CHANNEL_NAME_VALUE.CHAPTER_END_RECOMMEND })
    router.replace({ name: 'chapter', params: { contentId: nextBook.contentId, chapterId: nextBook.contentChapterId ?? '0' }, query: { finishRecommend: '1' } })
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
    pageSwiperRef.value?.reset()
  }
}

// 企微导粉
const showQwDialog = ref(false) // 是否展示企微弹窗
function getShowQwDialog() {
  return showQwDialog.value
}
const qwDialogForce = ref(false) // 是否强制展示
const qwDialogInfo = ref<any>(null) // 企微弹窗信息
const showQwRewards = ref(false) // 是否展示企微奖励弹窗
const qwRewards = ref<any>({}) // 企微奖励
// Note: 企业微信弹窗优先的时候, 需要先弹企业微信弹窗, 关闭后再弹支付弹窗
watchOnce(() => showQwDialog.value, () => {
  if (!showQwDialog.value) {
    if (!chapter.value?._pay)
      return
    if (configStore.$state.renewIceBreakingState)
      openPopupVip()
    else
      openPopupRecharge()
  }
})
const longPressRef = ref<HTMLElement>()
function checkForceQWDialog() {
  if (qwDialogForce.value) {
    showQwDialog.value = true
    return true
  }
  return false
}
async function getQwFansInfo(data: any) {
  qwDialogInfo.value = null
  qwDialogForce.value = false
  if (configStore.$state.strategy)
    return
  if (!data?.wpImportFansLink || !data?.wpImportFansMode)
    return
  if (bizModalRechargeInterception.value?.model) {
    watchOnce(() => bizModalRechargeInterception.value?.model, () => {
      if (!bizModalRechargeInterception.value?.model)
        getQwFansInfo(data)
    })
    return
  }

  showQwDialog.value = true
  qwDialogInfo.value = {
    bg: data.wpImportPopImgUrl,
    mode: data.wpImportFansMode, // 1: 获客链接, 2: 企微二维码
    link: data.wpImportFansLink,
    priority: data.wpImportPopWeight, // 1: 优先企微弹窗, 2: 优先支付弹窗
  }
  if (isDefined(data.wpImportFansForce))
    qwDialogForce.value = data.wpImportFansForce

  window.collectEvent('popupShow', {
    page: '阅读页',
    popup_name: data.wpImportFansMode === 1 ? '获客链接' : '企微二维码',
    link_code: data.wpImportLinkCode,
  })

  onLongPress(
    longPressRef,
    () => {
      window.collectEvent('popupClick', {
        page: '阅读页',
        popup_name: data.wpImportFansMode === 1 ? '获客链接(立即领取)' : '企微二维码(识别二维码)',
        link_code: data.wpImportLinkCode,
      })
    },
    { delay: 250 },
  )
}

// 企微导粉奖励
function onCloseQwRewardsDialog() {
  showQwRewards.value = false
  qwRewards.value = null
  configStore.updateStrategy(null)
}
if (configStore.$state.strategy && !configStore.alreadyGetStratrgy) {
  // 上报接口告知服务端
  configStore.updateAlreadyGetStratrgy(true)
  api.post<any, PostReadReportResponse>('/wp/fans/consume/code', {
    consumeCode: configStore.$state.strategy?.strategy,
    type: 'wpim',
  }).then((data) => {
    if (data.data) {
      if (configStore.$state.strategy?.reward) {
        qwRewards.value = {
          coin: configStore.$state.strategy?.reward,
          strategyId: configStore.$state.strategy?.strategy,
        }
      }
      // Note: 存在金币才显示弹窗
      if (qwRewards.value?.coin)
        showQwRewards.value = true
    }
    else {
      // eslint-disable-next-line no-console
      console.log('已经领取过奖励')
    }
  })
}
function goLink(link: string) {
  window.open(link)
}

// Popup: 购买须知
const showPayInfo = ref(false)
function openPopupPayInfo() {
  showPayInfo.value = true
}

// Note: 弹窗优先级控制, 只自动弹一次窗
// 1. 企微导粉 `showQwDialog`
// 2. 扫码关注 `modalQrcode`
// 3. 运营位活动 `bizModalOperationalPosition.value.modal`
const eventBusOperationalPosition = useEventBus<string>('modal:operationalPosition')
watch(() => chapter.value, () => {
  if (!chapter.value)
    return
  if (showQwDialog.value || showQwRewards.value)
    return

  if (chapter.value._pay)
    return

  if (chapter.value.subscribedMp || configStore.$state.noPopup) {
    eventBusOperationalPosition.emit('fetch')
    return
  }
  else if (chapter.value.sequenceNo > chapter.value.contentStartSubscribeChapter) {
    modalQrcode.value = true
    return
  }

  eventBusOperationalPosition.emit('fetch')
})

// 签约自动续费
onMounted(async () => {
  // Note: 签约页面返回会携带 `from_wxpay=1`
  // refs: https://pay.weixin.qq.com/wiki/doc/api/wxpay_v2/papay/chapter3_1.shtml
  const productId = window.localStorage.getItem(STORAGE_SIGN_PRODUCT_ID)
  if (route.query.from_wxpay && productId) {
    try {
      $showLoading()
      window.localStorage.removeItem(STORAGE_SIGN_PRODUCT_ID)

      let redirect = window.location.href
      if (!window.location.href.includes('skipPosition'))
        redirect = `${window.location.href}&skipPosition=1`

      // retry three times
      let times = 3
      while (times--) {
        const { data: exist } = await api.post('/pay/getContractInfo', {
          productId,
        })
        if (exist) {
          $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: PAY_LOCATION_PAGE.VIP, redirect })
          return
        }
        await sleep(1000)
      }

      return $toast('签约失败')
    }
    finally {
      $hideLoading()
    }
  }
})

// 完结书籍推荐
const recommends = ref<any[]>([])
const recommend = ref()
let recommendCache: any = null
async function fetchRecommends() {
  if (recommendCache) {
    recommends.value = [...recommendCache]
    recommend.value = recommends.value[0]
    return [...recommendCache]
  }

  try {
    const { data } = await api.get(`/read/next/contentList?contentId=${props.contentId}&maxNum=20`)
    data.forEach((item: any) => {
      item.score = getRecommendScore()
    })
    recommendCache = [...data]
    recommends.value = [...data]
    recommend.value = recommends.value[0]
    remove(recommends.value, recommend.value)
    return data
  }
  catch (error) {
    // $toast($formatErrorMessage(error))
    $hideLoading()
    $toast('没有续看的书，去书城看看', 3000)
    await sleep(3000)
    recommends.value = []
    recommend.value = null
    const historyStore = useHistoryStore()
    historyStore.$push(route.fullPath)
    router.replace({ name: 'home' })
  }
}
async function onChangeRecommend() {
  if (recommends.value.length === 0)
    await fetchRecommends()

  recommend.value = sample(recommends.value)
  remove(recommends.value, recommend.value)
  trackValueStore.set({ bookId: recommend.value.contentId, firstOrigin: 5, originName: 5, chlName: TRACK_CHANNEL_NAME_VALUE.CHAPTER_END_RECOMMEND })
  router.replace({ name: 'chapter', params: { contentId: recommend.value.contentId, chapterId: recommend.value.contentChapterId }, query: { finishRecommend: '1' } })
}

// 返回 `9.4 ~ 9.9` 之间的小数
function getRecommendScore() {
  return (Math.random() * 0.5 + 9.4).toFixed(1)
}

/* 前往投诉 */
function gotoComplaint() {
  router.replace({ name: 'complaint', query: { contentId: props.contentId, chapterId: props.chapterId } })
}
</script>

<template>
  <div v-if="skeleton" class="min-h-screen" :style="`background: ${theme.background}`">
    <div class="pt-20vh flex justify-center">
      <BasicSpinner class="mx-auto" />
    </div>
    <p class="mt-4 text-center font-bold">
      加载中...
    </p>
  </div>
  <div v-else-if="!chapter">
    <BizBlockError title="章节信息不存在" />
  </div>
  <BasicPageSwiper v-else ref="pageSwiperRef" :enabled="bookFinished && arrivedState.bottom" @pulled="onPagePulled">
    <template #footer>
      <div class="h-20 flex items-center justify-center text-#999 space-x-4">
        <div class="animate-spin">
          <i-icon-park-outline-loading />
        </div>
        <p>加载下一本书籍...</p>
      </div>
    </template>
    <div ref="containerRef" class="h-screen overflow-scroll" :style="`background: ${theme.background}`">
      <!-- Note: Custom  -->
      <BasicNavbar v-show="y === 0 || !popupTheme" scale :title="chapter.contentChapterName" />
      <transition name="slide">
        <div
          v-if="popupTheme && y !== 0" class="w-full fixed top-0 flex items-center z-10"
          :style="`background: ${theme.background}`"
        >
          <div class="p-2 pl-4" @click="gotoBack()">
            <i-icon-park-outline-left class="w-6 h-6" />
          </div>
        </div>
      </transition>

      <!-- Container -->
      <div class="px-20px py-10 min-h-screen" @click="onToggleTheme">
        <!-- Block: 完结 -->
        <div v-if="route.query.finishRecommend && !chapter._pay && recommend" class="pt-15px pb-10">
          <div class="flex flex-col items-center space-y-2">
            <p
              class="text-23px font-bold" :style="`color: ${{
                '#FFF': '#30303D',
                '#F5E9CF': '#916800',
                '#D2ECD3': '#008991',
                '#D0E6FE': '#004C91',
                '#F6E6E1': '#910000',
              }[theme.background]}`"
            >
              最后一章已看完
            </p>
            <p
              class="text-14px" :style="`color: ${{
                '#FFF': '#30303D70',
                '#F5E9CF': '#91680070',
                '#D2ECD3': '#00899170',
                '#D0E6FE': '#004C9170',
                '#F6E6E1': '#91000070',
              }[theme.background]}`"
            >
              根据您的阅读喜好，推荐以下内容
            </p>
          </div>
          <div v-if="recommend" class="mt-35px px-3 rounded-5px" :class="theme.background === '#FFF' ? 'bg-#0000000A' : 'bg-#FFFFFF40'">
            <div class="py-2 flex items-center justify-between">
              <span class="text-15px font-bold text-#000000B2">猜你喜欢</span>
              <p class="flex items-center space-x-1 text-#666666" @click.capture.stop="onChangeRecommend">
                <i-icon-park-outline-refresh class="w-12px h-12px" />
                <span class="text-13px">换一换</span>
              </p>
            </div>
            <div class="py-4 flex border-t border-#0000001A">
              <div class="relative">
                <img
                  :src="recommend.contentCoverUrl"
                  class="w-62px h-87px rounded-5px"
                >
                <span class="px-3px py-2px text-11px text-white bg-#FF5E43 rounded-tl-5px rounded-br-5px absolute top-0 left-0">推荐</span>
              </div>
              <div class="ml-15px flex-1 flex flex-col justify-between overflow-hidden">
                <div>
                  <div class="flex justify-between space-x-2">
                    <p class="text-15px font-bold flex-1 text-truncate">
                      {{ recommend.contentName }}
                    </p>
                    <p class="flex items-baseline text-primary">
                      <span class="text-17px font-bold">{{ recommend.score }}</span>
                      <span class="text-10px">分</span>
                    </p>
                  </div>
                  <p class="text-13px text-#666 line-clamp-2">
                    {{ recommend.introduce }}
                  </p>
                </div>
                <p class="text-12px text-#999">
                  {{ recommend.finishStatus ? '已完结' : '未完结' }} | {{ (recommend.wordNum / 10000).toFixed(1) }}万字
                </p>
              </div>
            </div>
          </div>
        </div>

        <p :style="THEME_FONT.chapterTitle">
          {{ chapter?.contentChapterName }}
        </p>
        <div :style="THEME_FONT.chapterContent">
          <p v-for="(item, index) in paragraphs" :key="index" :style="THEME_FONT.paragraphSpacing">
            {{ item }}
          </p>
        </div>
        <div class="mt-5 flex items-center justify-between">
          <span
            class="w-115px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
            :class="{ 'text-#999': !chapter.prev }" @click.stop="onPrevChapter"
          >
            上一章
          </span>
          <span class="w-80px h-39px lh-39px text-center text-14px bg-#0000000A rounded" @click.stop="popupTOC = true">
            目录
          </span>
          <span
            class="w-115px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
            @click.stop="onNextChapter"
          >
            下一章
          </span>
        </div>

        <!-- <div v-if="bookFinished && !chapter._pay" class="mt-4 h-10 flex items-center justify-center text-#999 space-x-4">
          <div class="animate-bounce">
            <i-icon-park-outline-arrow-up />
          </div>
          <p v-if="book?.finishStatus === 1">
            本书已完结, 上拉阅读其他书籍
          </p>
          <p v-else>
            未完待续，上拉阅读其他书籍
          </p>
        </div> -->
      </div>

      <!-- Popup: 设置主题 -->
      <BasicPopup v-model="popupTheme" :mask="false">
        <div class="w-full px-5 pt-25px py-10" :style="`background: ${theme.background}`">
          <div class="mb-5 flex space-x-25px items-center">
            <p class="flex-1 min-w-8 text-14px text-#999">
              字号
            </p>
            <div class="flex-1 flex items-center justify-between border-1 border-#999">
              <span
                class="w-90px h-35px lh-35px text-center text-14px bg-#0000000A rounded border-r-1 border-#999"
                :class="{ 'text-#999': theme.size === '24' }" @click="onChangeThemeSize(-1)"
              >
                A-
              </span>
              <span class="w-90px min-w-5 text-15px text-center">{{ theme.size }}</span>
              <span
                class="w-90px h-35px lh-35px text-center text-14px bg-#0000000A rounded border-l-1 border-#999"
                :class="{ 'text-#999': theme.size === '50' }" @click="onChangeThemeSize(1)"
              >
                A+
              </span>
            </div>
          </div>
          <div class="flex space-x-25px items-center">
            <p class="flex-1 min-w-8 text-14px text-#999">
              背景
            </p>
            <div class="flex flex-1 justify-between space-x-33px">
              <span
                v-for="item in THEME_BACKGROUNDS" :key="item" class="w-30px h-30px border rounded-full"
                :style="`background: ${item};`"
                :class="item === theme.background ? 'border-2 border-primary' : 'border-#ccc'"
                @click="theme.background = item"
              />
            </div>
          </div>
          <div class="mt-5 flex items-center justify-between">
            <span
              class="w-115px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
              :class="{ 'text-#999': !chapter?.prev }" @click="onPrevChapter"
            >
              上一章</span>
            <span
              class="w-80px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
              @click.stop="popupTOC = true"
            >
              目录
            </span>
            <span
              class="w-115px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
              @click="onNextChapter"
            >
              下一章
            </span>
          </div>
        </div>
      </BasicPopup>

      <BizPopupBookTOC
        v-model="popupTOC" :book="book" :content-id="props.contentId" :chapter-id="props.chapterId"
        :check-pass-goto-chapter="(index) => onTOCCheckPass(index)"
      />

      <!-- Popup: 充值列表 -->
      <BasicPopup v-model="popupRecharge" :closable="false">
        <BizTrackShow
          name="阅读页:充值列表" type="popup" :extra="{
            is_chapterend_recommend: finishRecommend ? '是' : '否',
            name: '阅读页充值档位弹窗',
            contentId: props.contentId,
            pay_status: '付费章节',
          }"
        >
          <div class="popupRecharge w-full px-5 pt-20px pb-30px bg-white rounded-t-lg relative">
            <div class="absolute p-5 right-0 top-0 z-10" @click="closePopupRecharge">
              <i-icon-park-close class="w-4 h-4 text-#999" />
            </div>
            <div class="flex items-center justify-center space-x-4 mb-5">
              <p class="text-19px text-#000 font-bold">
                解锁本章后继续观看
              </p>
            </div>

            <!-- 充值拦截活动 -->
            <div v-if="rechargeInterception?._exist" class="recharge-interception flex items-center justify-between text-white">
              <div class="flex items-center rounded relative">
                <p class="px-1 text-13px text-white font-bold">
                  {{ fen2yuan(rechargeInterception.redPacketAmount) }}元劵使用中
                </p>
              </div>
              <div class="flex items-baseline">
                <BasicCountdown
                  v-if="rechargeInterception.countDown"
                  :seconds="diffSeconds(Date.now(), rechargeInterception.expiredAt)"
                  @end="rechargeInterception = { _exist: false }"
                />
                <span class="ml-2 text-11px text-#00000099">后失效</span>
              </div>
            </div>

            <!-- 充值加购 -->
            <div v-else-if="rechargeAdd" class="add-wrapper">
              <div class="add-text">
                账户余额 {{ userStore.$state.coinBalance }}
                <span class="icon">金币</span>
              </div>
              <div class="add-btn">
                <span class="add-btn-text">{{ rechargeAdd.name }}</span>
                <BasicSwitch :is-open="rechargeAddState" @change="onChangeShowAdd" />
              </div>
            </div>
            <div class="mt-5 grid grid-cols-2 gap-10px relative">
              <BizTrackClick v-for="item in balanceProducts" :key="item.productId" :name="item.name" type="button">
                <BizPayProduct
                  :product="item" :content-id="contentId" :content-name="book?.contentName"
                  :chapter-id="chapterId" :chapter-index="chapter.sequenceNo" :pay-location="PAY_LOCATION_PAGE.CHAPTER"
                  :recharge-interception="rechargeInterception" :show-recharge-add="rechargeAddState" :recharge-add="rechargeAdd"
                  :skip-recharge-interception="!!skipRechargeInterception"
                />
              </BizTrackClick>
            </div>
            <div class="mt-6 text-center cursor-pointer text-14px text-#666" @click="openPopupPayInfo">
              购买须知
            </div>
          </div>
        </BizTrackShow>
      </BasicPopup>

      <!-- Popup: VIP 充值 -->
      <BasicPopup v-model="popupVip" :closable="false">
        <BizTrackShow
          name="阅读页:充值列表" type="popup" :extra="{
            is_chapterend_recommend: finishRecommend ? '是' : '否',
            name: '阅读页充值档位弹窗',
            contentId: props.contentId,
            pay_status: '付费章节',
          }"
        >
          <div class="bg-white rounded-t-4">
            <div class="absolute p-5 right-0 top-0 z-10" @click="closePopupVip">
              <i-icon-park-close class="w-4 h-4 text-#999" />
            </div>

            <p class="pt-20px text-19px text-center font-bold">
              还需<span class="text-#EB514C">{{ chapter.priceAmount - chapter.memberCoin }}金币</span>，解锁后续精彩内容
            </p>
            <!-- style: 1 -->
            <!-- eslint-disable-next-line vue/eqeqeq -->
            <template v-if="configStore.$state.renewIceBreakingStyleConfig == 1">
              <div v-if="iceBreakProduct" class="mx-4 pt-25px">
                <BizSingleIceBreakProduct
                  :product="iceBreakProduct"
                  highlight
                  :content-id="contentId" :content-name="book?.contentName"
                  :chapter-id="chapterId" :chapter-index="chapter.sequenceNo" :pay-location="PAY_LOCATION_PAGE.CHAPTER"
                />
              </div>
              <div class="p-4 pt-10px grid grid-cols-2 gap-10px">
                <BizPayProduct
                  v-for="product in products" :key="product.id"
                  :product="product" plain
                  :content-id="contentId" :content-name="book?.contentName"
                  :chapter-id="chapterId" :chapter-index="chapter.sequenceNo" :pay-location="PAY_LOCATION_PAGE.CHAPTER"
                  class="shrink-0"
                />
              </div>
            </template>
            <!-- eslint-disable-next-line vue/eqeqeq -->
            <template v-if="configStore.$state.renewIceBreakingStyleConfig == 2">
              <!-- style: 2 -->
              <div class="p-4 pt-25px grid grid-cols-2 gap-10px">
                <BizShortIceBreakProduct
                  v-if="iceBreakProduct" :product="iceBreakProduct"
                  class="shrink-0 w-full! h-93px"
                  highlight
                  :content-id="contentId" :content-name="book?.contentName"
                  :chapter-id="chapterId" :chapter-index="chapter.sequenceNo" :pay-location="PAY_LOCATION_PAGE.CHAPTER"
                />
                <BizPayProduct
                  v-for="product in balanceProducts" :key="product.id"
                  :product="product" plain
                  :content-id="contentId" :content-name="book?.contentName"
                  :chapter-id="chapterId" :chapter-index="chapter.sequenceNo" :pay-location="PAY_LOCATION_PAGE.CHAPTER"
                  class="shrink-0"
                />
              </div>
            </template>
            <BizBlockBuyVipCancel class="px-4" />
            <BizBlockBuyVipTips class="p-4 pb-8" />
          </div>
        </BizTrackShow>
      </BasicPopup>

      <!-- Modal: 购买须知 -->
      <BasicModal v-model="showPayInfo" :order="6">
        <div class="p-5">
          <div class="text-17px text-center font-bold mb-5">
            购买须知
          </div>
          <div class="text-12px">
            <p>1、会员属于虚拟商品，一经购买不可退换；</p>
            <p>2、未满18岁的未成年人须在监护人主导、同意下进行相关付款操作；</p>
            <p>3、付费出现问题，请在“我的”页面帮助中心下联系客服；</p>
            <p>4、会员仅在本公众号内使用。</p>
          </div>
          <a
            class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
            @click="showPayInfo = false"
          >
            知道了
          </a>
        </div>
      </BasicModal>

      <!-- Modal: 企微导粉 -->
      <BasicModal v-model="showQwDialog" :order="3">
        <BizTrackShow
          name="阅读页:企微导粉" type="modal" :extra="{
            is_chapterend_recommend: finishRecommend ? '是' : '否',
          }"
        >
          <div v-if="qwDialogInfo?.mode === 2" ref="longPressRef" class="p-5 text-center">
            <div class="w-full relative" style="aspect-ratio: 1/1;">
              <img
                v-if="qwDialogInfo?.bg"
                class="w-full h-full absolute top-0 left-0" src="@/assets/qw-bg.png"
                alt="背景图片"
              >
              <img
                v-else
                class="w-full h-full absolute top-0 left-0" src="@/assets/qw-bg.png"
                alt="背景图片"
              >
              <img
                class="w-45% h-45% absolute top-60% left-50%" style="transform: translate(-50%, -50%);"
                :src="qwDialogInfo?.link" alt="二维码图片"
              >
            </div>
            <a
              class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
              @click="showQwDialog = false"
            >
              知道了
            </a>
          </div>
          <div v-else ref="longPressRef" class="text-center">
            <img class="mw-80% mh-80%" :src="qwDialogInfo?.bg" alt="背景图片" @click="goLink(qwDialogInfo?.link)">
          </div>
        </BizTrackShow>
      </BasicModal>

      <!-- Modal: 企微导粉奖励 -->
      <BasicModal v-model="showQwRewards" :order="4">
        <div class="p-5 text-center">
          <div class="font-bold">
            <p class="text-14px">
              天降福利
            </p>
            <p class="text-18px">
              恭喜您获得<span class="text-red">{{ qwRewards?.coin }}</span>金币
            </p>
          </div>
          <a
            class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
            @click="onCloseQwRewardsDialog"
          >
            领取奖励
          </a>
        </div>
      </BasicModal>

      <!-- 企微导粉/企微导粉奖励 遮罩层 -->

      <!-- Modal: 二维码 -->
      <BasicModal v-model="modalQrcode" :order="2">
        <BizTrackShow
          name="阅读页:关注二维码" type="modal" :extra="{
            is_chapterend_recommend: finishRecommend ? '是' : '否',
          }"
        >
          <div class="p-5 text-center">
            <div class="text-17px font-bold">
              <p>关注公众号</p>
              <p>继续阅读精彩内容</p>
            </div>
            <img class="mx-a my-10px w-130px" :src="chapter.mpQrUrl" alt="图片:二维码">
            <div class="text-12px">
              <p>长按上图二维码3秒</p>
              <p>点击 <span class="text-primary">【识别二维码】</span>点击<span class="text-primary">【关注公众号】</span></p>
              <p>精彩内容继续看</p>
            </div>
            <a
              v-if="!chapter.forceAttentionMp"
              class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
              @click="modalQrcode = false"
            >
              知道了
            </a>
          </div>
        </BizTrackShow>
      </BasicModal>
      <!-- 反馈 -->
      <div class="complaint-float" @click.stop="gotoComplaint">
        <img class="icon" src="@/assets/icon-complaint.png" alt="反馈">
        <div class="text">
          反馈
        </div>
      </div>
    </div>
  </BasicPageSwiper>
</template>

<style lang="scss" scoped>
.popupRecharge {
  background: #fff;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.slide-enter-active {
  transition: all 0.2s ease;
  transform: translateY(0%);
}

.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
}

.add-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.add-text {
  color: #666;
}

.add-btn {
  color: #ff9928;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-btn-text {
  margin-right: 6px;
  font-weight: bold;
}

.recharge-interception {
  background: url("@/assets/bg-recharge-interception.png");
  background-size: 100% 100%;
  height: 30px;
  padding-left: 25px;
}

:deep(.countdown .bg-primary) {
  background: #FF6E0F66;
}

.complaint-float {
  position: fixed;
  right: 0;
  top: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  background: #916800;
  border-radius: 15px 0 0 15px;
  padding: 3px 5px;
  line-height: 1;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.3);
  color: white;

  .icon {
    width: auto;
    height: 2em;
  }
}
</style>
