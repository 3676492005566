<!--
  单独的支付页
-->
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { $chooseWXPay } from '@/wechat'
import { useToastStore } from '@/stores'
import { ERROR_PAGE_LEVEL } from '@/constants'

const props = defineProps<{
  productId?: string
  code?: string
}>()

const { $toast } = useToastStore()

const failed = ref('')
const route = useRoute()
const router = useRouter()
onMounted(async () => {
  const _cancel = window.decodeURIComponent(route.query.cancel as string ?? '')
  const _created = window.decodeURIComponent(route.query.created as string ?? '')
  const redirect = window.decodeURIComponent(route.query.redirect as string ?? '')

  if (_cancel) {
    let url = `${redirect}&cancel=1`
    if (route.query.skipRechargeInterception)
      url = `${url}&skipRechargeInterception=1`
    window.location.replace(url)
    return
  }

  if (_created) {
    if (redirect) {
      window.location.replace(`${redirect}&created=1`)
      return
    }
    if (window.history.length > 1) {
      window.history.back()
      return
    }

    router.replace({
      name: 'error',
      query: {
        level: ERROR_PAGE_LEVEL.FATAL,
        message: '很抱歉，是否失败请退出重试',
        redirect: window.encodeURIComponent(window.location.href),
      },
      replace: true,
    })
    return
  }

  let paymentPayload = {}
  try {
    const { data } = await api.post<any, PostOrderCreatewxmporderResponse>('/order/createWXMPOrder', {
      productId: props.productId,
      payMPCode: props.code,
      memberId: route.query.memberId,
      appId: route.query.appId,
      promotionId: route.query.promotionId || '0',
      paySid: route.query.paySid || '0',
      payLocation: route.query.payLocation,
      appVersion: route.query.appVersion,
      platform: route.query.OS,
      activityId: route.query.activityId,
      contentId: route.query.contentId,
      contentChapterId: route.query.chapterId,
      callbackUrl: window.decodeURIComponent(route.query.callbackUrl as string ?? ''),
      rechargeOaaId: route.query.rechargeOaaId,
      rechargeStrategyId: route.query.rechargeStrategyId,
      firstOrigin: route.query.firstOrigin || '0',
      originName: route.query.originName || '0',
      chlName: route.query.chlName || '0',
    })
    // alias: `packAge` => `package`
    data.package = data.package || data.packAge
    paymentPayload = { ...data }
  }
  catch (error: any) {
    console.error(error)
    failed.value = error.msg || '服务器错误'
    return
  }

  try {
    await $chooseWXPay(paymentPayload)
    $toast('支付成功')
    let url = window.location.href
    if (!window.location.href.includes('&created=1'))
      url = `${window.location.href}&created=1`
    window.location.replace(url)
  }
  catch (error) {
    console.error(error)
    $toast('取消支付')
    let url = window.location.href
    if (!window.location.href.includes('&created=1&cancel=1'))
      url = `${window.location.href}&created=1&cancel=1`
    setTimeout(() => {
      window.location.replace(url)
    }, 500)
  }
})

function onRetry() {
  const redirect = window.decodeURIComponent(route.query.redirect as string ?? '')
  if (window.history.length > 1) {
    window.history.back()
    return
  }
  window.location.replace(redirect)
}
</script>

<template>
  <div class="w-full h-screen bg-white">
    <div class="pt-20vh flex justify-center">
      <BasicSpinner v-if="!failed" class="mx-auto" />
    </div>
    <p class="mt-8 font-bold text-center">
      <span>{{ failed || '充值中，请稍后' }}</span>
    </p>
    <div class="mt-40 flex justify-center">
      <transition name="fade">
        <button
          v-if="failed"
          class="px-4 py-2 text-primary border-primary border-1 border-solid rounded-full"
          @click="onRetry"
        >
          重试
        </button>
      </transition>
    </div>
  </div>
</template>
