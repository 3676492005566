import VueRouter from 'vue-router'
import queryString from 'query-string'
import { CODE_SOURCE, ERROR_PAGE_LEVEL } from './constants'
import { generateThirdOAuthUrl } from './wechat'
import { useConfigStore, useHistoryStore, useQueryStore, useTrackValueStore, useUserStore } from '@/stores'
import routes from '@/routes'
import { track } from '@/track'
import './ployfills'

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' }
  },
})

// Router Guards
router.beforeEach(async (to, form, next) => {
  // Set document title
  document.title = to?.meta?.title

  // Init query store
  const queryStore = useQueryStore()
  queryStore.init(to.query)

  const configStore = useConfigStore()
  const userStore = useUserStore()

  window.entryUrl = window.entryUrl || window.location.href

  // Update document title
  if (configStore.$state.wxAppName)
    document.title = configStore.$state.wxAppName

  if (to.meta?.requiresAuth === false) {
    next()
  }
  // Note: Don't exist `max_app_id`
  else if (!queryStore.$state.max_app_id) {
    next({
      name: 'error',
      query: {
        level: ERROR_PAGE_LEVEL.FATAL,
        message: '很抱歉，系统没有识别您的身份',
        redirect: window.encodeURIComponent(window.location.href),
      },
      replace: true,
    })
  }
  // Note: Automatic add common query. `max_app_id`, `max_pid`, `max_sid`
  else if (!to.query.existed_common) {
    next({
      ...to as any,
      query: {
        ...to.query,
        existed_common: 1,
        max_app_id: queryStore.$state.max_app_id,
        max_pid: queryStore.$state.max_pid,
        max_sid: queryStore.$state.max_sid,
      },
      replace: true,
    })
  }
  else if (to.meta?.requiresAuth !== false && !userStore.isLogin()) {
    try {
      // Note: Fetch global config
      await configStore.init()
      const oAuthDomain = configStore.$state.oauthDomain
      const oAuthURL = generateThirdOAuthUrl({
        appId: configStore.$state.mpAppId,
        componentId: configStore.$state.componentAppId,
        redirect: `${oAuthDomain}/authorize?redirect=${window.encodeURIComponent(`${window.location.origin}/authorize?${
            queryString.stringify({
              next: window.encodeURIComponent(to.fullPath),
              codeSource: CODE_SOURCE.TARGET,
              appId: configStore.$state.appId,
              max_app_id: queryStore.$state.max_app_id,
              max_pid: queryStore.$state.max_pid,
              max_sid: queryStore.$state.max_sid,
            })
          }`)}`,
      })
      window.location.replace(oAuthURL)
    }
    catch (error: any) {
      console.error(error)
      next({
        name: 'error',
        query: {
          level: ERROR_PAGE_LEVEL.DEFAULT,
          message: error.msg || error.message,
          redirect: window.encodeURIComponent(window.location.href),
        },
        replace: true,
      })
    }
  }
  else {
    const trackValueStore = useTrackValueStore()
    const route = to
    // Set track value
    // refs: https://doc.weixin.qq.com/sheet/e3_AbAA8Ab1AA0MTQcebGWTIyH9wx7pC?scode=AAsAkAdiAAs002NeV3AbAA8Ab1AA0&tab=lmg39t
    let firstOrigin = {
      home: 2,
      search: 3,
      history: 4,
      chapter: 5,
      // _: 6, // 消息推送
      category: 7,
    // _: 8, // 书架
    }[route.name as string] || 0
    if (route.query.messageSourceType)
      firstOrigin = 6

    // `messageSourceType`
    // FOLLOW_REPLY_MESSAGES(2, "关注回复消息"),
    // CUSTOMER_SERVICE_MESSAGES(5, "客服消息"),
    // KEYWORD_REPLY(4, "关键词回复"),
    // TEMPLATE_MESSAGE(7, "订阅消息"),
    // DELAY_MESSAGE(3, "延时消息"),
    // 客服消息 13, 关注回复 14, 订阅消息 15, 关键词回复 16, 关注延迟 17
    let chlName = 0
    if (route.query.messageSourceType) {
      chlName = {
        2: 14,
        5: 13,
        7: 15,
        4: 16,
        3: 17,
      }[route.query.messageSourceType as string] || 0
    }
    if (route.query.max_pid) {
      chlName = 1
      firstOrigin = 1
    }
    let bookId = '0'
    if (route.params.contentId)
      bookId = route.params.contentId

    const originName = firstOrigin
    trackValueStore.set({
      bookId,
      firstOrigin,
      originName,
      chlName,
    })

    try {
      await configStore.init()
      await userStore.init()
    }
    catch (error: any) {
      console.error(error)
      next({
        name: 'error',
        query: {
          level: ERROR_PAGE_LEVEL.DEFAULT,
          message: error.msg || error.message,
          redirect: window.encodeURIComponent(window.location.href),
        },
        replace: true,
      })
    }
    next()
  }
})

// Tracking
router.afterEach((to, from) => {
  const params: Record<string, any> = {
    fromUrl: from.fullPath,
    fromTitle: from.meta?.title ?? '',
    toUrl: to.fullPath,
    toTitle: to.meta?.title ?? '',
  }
  if (to.name === 'book') {
    params.contentId = to.params.contentId
  }
  else if (to.name === 'chapter') {
    params.contentId = to.params.contentId
    params.chapterId = to.params.chapterId
    if (to.query.finishRecommend)
      params.toTitle = '文末推荐'
  }
  else if (to.name === 'activity') {
    params.activityId = to.params.activityId
  }
  track('pageView', params)
  window.collectEvent('pageview', params)
})

// Store the route stack
router.afterEach((to, from) => {
  const historyStore = useHistoryStore()
  if (['authorize', 'paying'].includes(to.name ?? '')) {
    // Skip record
  }
  else if (to.name === 'history' && to.query.from === 'member') {
    historyStore.$push(to.fullPath)
  }
  // Note: same route || tabs route
  else if (to.name === from.name || ['history', 'home', 'mm', 'category', 'member'].includes(to.name ?? '')) {
    historyStore.$replace(to.fullPath)
  }
  else {
    historyStore.$push(to.fullPath)
  }
})

export default router
