<script setup lang="ts">
import { useUserStore } from '@/stores'

const userStore = useUserStore()

/**
 * 前往小程序，关闭公众号迁移公告
 */
function closeMpOutPop() {
  window.location.replace(userStore.transferLink)
}
</script>

<template>
  <BasicModal v-model="userStore.transfer" :transparent="true">
    <div class="mp-pop">
      <div class="tit">
        公告
      </div>
      <div class="cont">
        <div>亲爱的用户，我们即将对阅读服务进行升级</div>
        <div class="bold">
          您在公众号的账户余额等资产，将会安全迁移至小程序
        </div>
        <div>为确保您的权益，请移步至小程序，我们将继续为您提供优质服务</div>
      </div>
      <div class="btn" @click="closeMpOutPop">
        前往小程序
      </div>
    </div>
  </BasicModal>
</template>

<style lang="scss" scoped>
.mp-pop {
  background: white;
  margin: 30px auto 0;
  width: 272px;
  border-radius: 10px;
  overflow: hidden;

  .tit {
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
    font-size: 1.2em;
    line-height: 1;
  }

  .cont {
    padding: 15px 25px 20px;
    text-align: justify;
    font-size: 14px;
    line-height: 21px;

    .bold {
      font-weight: bold;
      margin: 1em 0;
    }
  }

  .btn {
    background: #ff6e0f;
    color: white;
    text-align: center;
    font-weight: 600;
    line-height: 54px;
  }
}
</style>
